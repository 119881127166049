import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiAccountCog, mdiApplicationCogOutline, mdiWrenchClock } from '@mdi/js';
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore)
        return { authStore, role };
    },

    components: {
        SvgIcon
    },

    props: {},


    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
    },

    data() {
        return {
            menu: []
        };
    },

    created() {
        if (!authTokenService.getToken()) return;

        this.setCurrentMenu()
        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        actionHandler(routeName) {
            if (routeName == '') return;
            if (this.$route.name == routeName) return;
            this.$router.push({ name: routeName });
        },

        setCurrentMenu() {
            const availableMenu = {
                "admin": [
                    { active: false, type: "mdi", icon: 'mdi-puzzle', label: 'Templates', i18nIndex: 1, route: 'templates' },
                    { active: false, type: "mdi", icon: "mdi-book-open-variant", label: 'Annuaire', i18nIndex: 2, route: 'contactBooks' },
                    { active: false, type: "custom", icon: mdiAccountCog, label: 'Gestion des accès', i18nIndex: 3, route: 'usersManagement' },
                    { active: false, type: "custom", icon: mdiWrenchClock, label: 'Automatisations', i18nIndex: 4, route: 'processings' },
                    { active: false, type: "custom", icon: mdiApplicationCogOutline, label: 'Réglages', i18nIndex: 5, route: 'reasons' },
                ],
                "manager": [
                    { active: true, type: "mdi", icon: 'mdi-plus', label: 'Nouveau ticket', i18nIndex: 7, route: 'ticketNew' },
                    { active: false, type: "mdi", icon: 'mdi-message-text', label: 'Tickets', i18nIndex: 6, route: 'ticketsOpen' },
                    { active: false, type: "mdi", icon: "mdi-book-open-variant", label: 'Annuaire', i18nIndex: 2, route: 'contactBooks' },
                    { active: false, type: "custom", icon: mdiAccountCog, label: 'Gestion des accès', i18nIndex: 3, route: 'usersManagement' },
                    { active: false, type: "custom", icon: mdiWrenchClock, label: 'Automatisations', i18nIndex: 4, route: 'processings' },
                    { active: false, type: "custom", icon: mdiApplicationCogOutline, label: 'Réglages', i18nIndex: 5, route: 'ticketSettings' },
                ],
                "agent": [
                    { active: true, type: "mdi", icon: 'mdi-plus', label: 'Nouveau ticket', i18nIndex: 7, route: 'ticketNew' },
                    { active: false, type: "mdi", icon: 'mdi-message-text', label: 'Tickets', i18nIndex: 6, route: 'ticketsOpen' },
                ],
            }
            this.menu = availableMenu[this.role]
        },

        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });


            let formsLinks = [
                'templates', 'templatesNew', 'templatesDetail',
                'builders', 'buildersNew', 'buildersDetail',
            ];

            if (formsLinks.includes(routeName)) {
                let elementToUpdate = this.menu.find(element => element.route == 'templates')
                elementToUpdate.active = true;
                return;
            }

            let timesLinks = [
                'processings', 'processingNew', 'processingDetail',
                'notifications', 'notificationNew', 'notificationDetail',
            ];

            if (timesLinks.includes(routeName)) {
                let elementToUpdate = this.menu.find(element => element.route == 'processings')
                elementToUpdate.active = true;
                return;
            }

            let settingAppsLinks = [];

            if (this.authStore.role == "admin") {
                settingAppsLinks = [
                    'sources', 'sourceNew', 'sourceDetail',
                    'reasons', 'reasonNew', 'reasonDetail',
                    'tags', 'tagNew', 'tagDetail',
                    'grades', 'gradeNew', 'gradeDetail',
                    'integrations'
                ];
            } else {
                settingAppsLinks = [
                    'reasons', 'reasonNew', 'reasonDetail',
                    'ticketSettings', 'integrations'
                ];
            }

            if (settingAppsLinks.includes(routeName)) {
                let elementToUpdate;
                if (this.authStore.role == "admin") elementToUpdate = this.menu.find(element => element.route == 'reasons')
                else elementToUpdate = this.menu.find(element => element.route == 'ticketSettings')
                elementToUpdate.active = true;
                return;
            }


            let managementLinks = [
                'usersManagement', 'usersManagementDetail', 'usersManagementNew',
                'groupsManagement', 'groupsManagementDetail', 'groupsManagementNew',
                'jobsManagement', 'jobsManagementNew', 'jobsManagementDetail', 'groupsManagementAggregated'
            ];

            if (managementLinks.includes(routeName)) {
                let managementElement = this.menu.find(element => element.route == 'usersManagement')
                managementElement.active = true;
                return;
            }

            let ticketsLinks = [
                'tickets', 'ticketDetail',
                'ticketsFinished', 'ticketHistories', 'ticketsOpen'
            ];

            if (ticketsLinks.includes(routeName)) {
                let ticketElement = this.menu.find(element => element.route == 'ticketsOpen')
                ticketElement.active = true;
                return;
            }

            let generalElement = this.menu.find(element => element.route == routeName)
            if (generalElement) {
                generalElement.active = true;
                return;
            }
        }
    },
};