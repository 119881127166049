import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";

export default {
    components: {},

    props: {
        dialog: {
            default: false
        },
        isDisabled: {
            default: false,
        },
        groups: {
            default: () => {
                return []
            }
        }
    },

    watch: {
        dialog(val) {
            if (val && !this.isDisabled) this.retrieveGroups();
        }
    },

    data() {
        return {
            loading: false,
            cardWidth: 600,
            search: null,
            groupsEdit: [],
            availableGroups: []
        };
    },

    created() {
        this.setCardWidth()
        window.addEventListener("resize", this.setCardWidth, { passive: true });
        this.groupsEdit = this.groups;
    },

    mounted() { },

    methods: {
        setCardWidth() {
            if (window.innerWidth < 960) this.cardWidth = window.innerWidth - 80;
            else this.cardWidth = 600;
        },

        close() {
            this.groupsEdit = this.groups;
            this.$emit('update:dialog', false)
        },

        async actionHandler() {
            this.loading = true
            try {
                let sendGroups = [];
                this.groupsEdit.forEach(element => {
                    sendGroups.push(element.id)
                });
                const request = await apiService.put(`/agents/${this.$route.params.id}?action=groups`, { groups: sendGroups });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, {message: response.message,isError: false,})
                this.$emit("refresh")
                this.$emit('update:dialog', false)
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },

        async retrieveGroups() {
            this.loading = true
            try {
                let data = []
                const request = await apiService.get("/groups");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                response.data.forEach(el => {
                    data.push({id: el.id, label: el.label})
                })
                this.availableGroups = data;
            } catch (error) {
                this.availableGroups = [];
            } finally {
                this.loading = false
            }
        },
    },
};




