import { createRouter, createWebHistory } from 'vue-router'

import defaultRoutes from './defaultRoutes';
import loggedRoutes from './loggedRoutes';
import ticketRoutes from './ticketRoutes';
import accessManagementRoutes from "./accessManagementRoutes";
import adminRoutes from "./adminRoutes";
import errorRoutes from './errorRoutes';
import { useAuthStore } from '@/stores/AuthStore';

import authTokenService from '@/services/authTokenService';

let routes = [];

routes = routes.concat(
  defaultRoutes, loggedRoutes, ticketRoutes, adminRoutes,
  accessManagementRoutes, errorRoutes
)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const { requiredAuth, roles } = to.meta;

  if (requiredAuth && !authTokenService.getToken()) {
    return next({ path: "/" });
  }

  if (requiredAuth && roles && !roles.includes(authStore.role)) {
    return next({ path: "/tickets" });
  }

  next();
})

export default router
