import authTokenService from "@/services/authTokenService";
import routeService from "@/services/routeService";

export default {
    components: {},

    props: {

    },

    watch: {
        '$route'(to) {
            this.setActiveLink(to.name)
        },
        "$i18n.locale": function() {
            this.menu[0].title = this.$t('ticket["Tickets ouverts"]')
            this.menu[1].title = this.$t('ticket["Tickets terminés"]')
            this.menu[2].title = this.$t('ticket["Tous les tickets"]')
        }
    },

    data() {
        return {
            rail: false,
            drawer: true,
            menu: [
                { title: this.$t('ticket["Tickets ouverts"]'), active: false, route: 'ticketsOpen' },
                { title: this.$t('ticket["Tickets terminés"]'), active: false, route: 'ticketsFinished' },
                { title: this.$t('ticket["Tous les tickets"]'), active: false, route: 'tickets' },
            ],
        };
    },

    created() {
        if(!authTokenService.getToken()) return;
        this.setActiveLink(this.$route.name)
    },

    mounted() { },

    methods: {
        setActiveLink(routeName) {
            this.menu.forEach(element => {
                element.active = false;
            });

            let ticketElement = this.menu.find(element => element.route == routeName)

            if (ticketElement) {
                ticketElement.active = true;
                return;
            }
        },

        actionHandler(routeName) {
            routeService.goToRoute(routeName)
        },

        refreshHandler() {
            this.$emit('refreshList', this.$route.name)
        }
    },
};