<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('dialog["Les actions"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div>
        <v-tabs v-model="tab" density="compact" class="px-0 mx-0">
          <v-tab value="1" class="mx-2 px-0 text-body-1 font-weight-medium">
            {{ $t(`admin["Changer la priorité"]`) }}
          </v-tab>
          <v-tab value="2" class="mx-2 px-0 text-body-1 font-weight-medium">
            {{ $t(`admin["Fermer un ticket"]`) }}
          </v-tab>
          <v-tab value="3" class="mx-2 px-0 text-body-1 font-weight-medium">
            {{ $t(`admin["Modifier les participants du ticket"]`) }}
          </v-tab>
          <v-tab value="4" class="mx-2 px-0 text-body-1 font-weight-medium">
            {{ $t(`admin["Envoyer un email"]`) }}
          </v-tab>
        </v-tabs>

        <v-window
          v-model="tab"
          class="py-2 px-5"
          style="max-height: 300px !important; overflow-y: scroll"
        >
          <v-window-item value="1">
            <h4 class="mt-5 font-weight-medium">
              {{ $t(`datatable.header["Priorité"]`) }}
            </h4>
            <v-select
              v-model="actionEdit[0].model"
              :items="actionEdit[0].values"
              item-title="label"
              item-value="value"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mb-2 mt-2"
              :disabled="isDisabled"
              return-object
              clearable
              :menu-props="{
                closeOnClick: true,
                closeOnContentClick: true,
              }"
            >
              <template v-slot:selection="{ item }">
                {{
                  $t(`fields[&quot;${item.title}&quot;]`).includes("fields")
                    ? item.title
                    : $t(`fields["${item.title}"]`)
                }}
              </template>

              <template v-slot:item="{ item }">
                <div>
                  <v-list-item
                    :title="
                      $t(`fields[&quot;${item.title}&quot;]`).includes('fields')
                        ? item.title
                        : $t(`fields[&quot;${item.title}&quot;]`)
                    "
                    density="compact"
                    @click="actionEdit[0].model = item.value"
                  >
                  </v-list-item>
                </div>
              </template>
            </v-select>
            <div class="d-flex align-center mt-5">
              <h4 class="font-weight-medium mr-8">
                {{ $t('admin["Activer"]') }}
              </h4>
              <div>
                <v-switch
                  v-model="actionEdit[0].enable"
                  :label="
                    actionEdit[0].enable
                      ? this.$t(`form.label[&quot;Oui&quot;]`)
                      : this.$t(`form.label[&quot;Non&quot;]`)
                  "
                  hide-details=""
                  color="teal-darken-1"
                  :disabled="isDisabled"
                  density="compact"
                ></v-switch>
              </div>
            </div>
          </v-window-item>

          <v-window-item value="2">
            <div class="d-flex align-center pt-2">
              <h4 class="font-weight-medium mr-8">
                {{ $t('admin["Activer"]') }}
              </h4>

              <v-switch
                v-model="actionEdit[1].enable"
                :label="
                  actionEdit[1].enable
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
          </v-window-item>
          <v-window-item value="3">
            <h4 class="font-weight-medium">
              {{ $t('ticket["Service"]') }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[2].group"
              :items="availableGroups"
              :item-title="getTitleString"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :loading="loading"
              :disabled="isDisabled"
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["A"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[2].A"
              :items="availableUsers"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              clearable
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["cc"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[2].CC"
              :items="availableUsers"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              multiple
              clearable
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["cci"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[2].CCI"
              :items="availableUsers"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              multiple
              clearable
            >
            </v-autocomplete>

            <p class="mt-2 text-red text-caption font-weight-bold">
              *{{
                $t(
                  'admin["Pour les champs a, cc et cci il faut séparer les adresses email par des points-virgules"]'
                )
              }}.
            </p>

            <div class="d-flex align-center pt-2">
              <h4 class="font-weight-medium mr-8">
                {{ $t('admin["Activer"]') }}
              </h4>
              <v-switch
                v-model="actionEdit[2].enable"
                :label="
                  actionEdit[2].enable
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
          </v-window-item>

          <v-window-item value="4">
            <h4 class="font-weight-medium">
              {{ $t(`admin["De"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[3].De"
              :items="users"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              clearable
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["A"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[3].A"
              :items="users"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              clearable
              multiple
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["cc"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[3].CC"
              :items="users"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              clearable
              multiple
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["cci"]`) }}
            </h4>

            <v-autocomplete
              v-model="actionEdit[3].CCI"
              :items="users"
              :item-title="getTitleUser"
              item-value="id"
              return-object
              variant="outlined"
              density="compact"
              hide-details=""
              :disabled="isDisabled"
              clearable
              multiple
            >
            </v-autocomplete>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["Sujet"]`) }}
            </h4>

            <v-text-field
              v-model="actionEdit[3].subject"
              variant="outlined"
              density="compact"
              :disabled="isDisabled"
              hide-details=""
            ></v-text-field>

            <h4 class="font-weight-medium mt-2">
              {{ $t(`admin["Corps"]`) }}
            </h4>

            <v-textarea
              v-model="actionEdit[3].body"
              variant="outlined"
              density="compact"
              hide-details=""
              :readonly="isDisabled"
            ></v-textarea>

            <p class="mt-2 text-red text-caption font-weight-bold">
              *{{
                $t(
                  'admin["Pour les champs a, cc et cci il faut séparer les adresses email par des points-virgules"]'
                )
              }}.
            </p>

            <div class="d-flex align-center pt-2">
              <h4 class="font-weight-medium mr-8">
                {{ $t('admin["Activer"]') }}
              </h4>
              <v-switch
                v-model="actionEdit[3].enable"
                :label="
                  actionEdit[3].enable
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
          </v-window-item>
        </v-window>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="small"
          class="mr-4 px-5"
          color="teal"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="small"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :disabled="isDisabled"
          :loading="loading"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/processingTimeActionsDialog.js"></script>