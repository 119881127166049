import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { API_URL, OPEN_SNACKBAR } from "@/utils/contants";
import DeleteDialog from "@/components/online/manager/accessManagement/dialogs/DeleteDialog.vue";
import UserManagementDisableAccountDialog from "@/components/online/manager/accessManagement/dialogs/UserManagementDisableAccountDialog.vue";
import UserManagementGroupAssignmentDialog from "@/components/online/manager/accessManagement/dialogs/UserManagementGroupAssignmentDialog.vue";
import UserManagementJobAssignmentDialog from "@/components/online/manager/accessManagement/dialogs/UserManagementJobAssignmentDialog.vue";
import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/AuthStore";
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore)
        return { authStore, role };
    },

    components: {
        DeleteDialog,
        UserManagementDisableAccountDialog,
        UserManagementGroupAssignmentDialog,
        UserManagementJobAssignmentDialog,
    },

    props: {},

    watch: {
        "$i18n.locale": function() {
            this.title = this.$route.params.id ? "" : this.$t('manager.userManagement["Ajouter un agent"]');

            this.rules = {
                required: v => !!v || this.$t('form.validation.required'),
                email: v => /.+@.+\..+/.test(v) || this.$t('form.validation.email'),
                phone: v => /^[0-9]{10,}$/.test(v) || this.$t('form.validation.phone')
            }
        },

        '$route'(to) {
            this.title = to.params.id ? "" : this.$t('manager.userManagement["Ajouter un agent"]');
            this.userIdentity = null;

            if (to.name == 'usersManagementNew') {
                this.$refs.form.reset();
                this.isDisabled = false;
                this.isDisabledBtn = false;
            } else if (to.name == 'usersManagementDetail') {
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
    },

    data() {
        return {
            loading: false,
            isDisabled: false,
            isDisabledBtn: false,
            loadingDelete: false,
        
            dialogDelete: false,
            dialogDisable: false,
            dialogJob: false,
            dialogGroup: false,

            userIdentity: null,
            title: this.$route.params.id ? "" : this.$t('manager.userManagement["Ajouter un agent"]'),
            
            uploadImage: null,
            login: null,
            lastname: null,
            firstname: null,
            email: null,
            phone1: null,
            phone2: null,
            comment: null,
            avatar: null,
            phoneError1: false,
            phoneError2: false,
            isActive: false,
            userJob: null,
            groups: [],

            selectedRole: null,
            availableRoles: ['admin', 'agent', 'manager'],

            rules: {
                required: v => !!v || this.$t('form.validation.required'),
                email: v => /.+@.+\..+/.test(v) || this.$t('form.validation.email'),
                phone: v => /^[0-9]{10,}$/.test(v) || this.$t('form.validation.phone')
            },
        };
    },

    created() {
        if(!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.isDisabledBtn = false;
        }

        if (this.$route.params.id) {
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() {

    },

    methods: {
        goBack() {
            this.$router.push({ name: "usersManagement", replace: true });
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        getFilePath(file, isRemote = false) {
            if (file == null && isRemote) return `${API_URL}/images/default.jpg`
            if (file != null && isRemote) return `${API_URL}/images/${file}`
            return `${location.origin}/images/${file}`
        },

        preview() {
            return URL.createObjectURL(this.uploadImage);
        },

        createDynamicInputFile() {
            let imageTag = document.createElement('input');
            imageTag.type = "file";
            imageTag.accept = ".jpg, .png, .jpeg";
            imageTag.classList.add("d-none");
            document.body.appendChild(imageTag);
            imageTag.click();

            imageTag.addEventListener("change", function () {
                const fileList = [...this.files];
                setUploadImage(fileList[0])
            })

            const setUploadImage = (file) => {
                this.uploadImage = file;
                imageTag.remove();
            };
        },

        async retrieveSingle() {
            this.loading = true;
            try {
                const request = await apiService.get(`/agents/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.userIdentity = `${response.data.lastname} ${response.data.firstname}`
                this.login      = response.data.login;
                this.lastname   = response.data.lastname;
                this.firstname  = response.data.firstname;
                this.email      = response.data.email;
                this.phone1     = response.data.phone1;
                this.phone2     = response.data.phone2;
                this.comment    = response.data.comment;
                this.isActive   = response.data.isActive == 1;
                this.userJob    = response.data.userJob;

                if(this.role == "admin") {
                    this.selectedRole    = response.data.role;
                }
                
                this.groups = [];
                response.data.groups.forEach(element => {
                    this.groups.push({id: element.group.id, label: element.group.label})
                });
            } catch (error) {
                this.$router.push({ name: "usersManagement", replace: true });
            } finally {
                this.loading = false;
            }
        },

        async submitHandler() {
            this.loading = true;
            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error('Des champs sont manquants');

                let dataSend = new FormData();
                if (this.uploadImage != null) dataSend.append("avatar", this.uploadImage);
                dataSend.append("login", this.login == null ? "" : this.login);
                dataSend.append("lastname", this.lastname == null ? "" : this.lastname);
                dataSend.append("firstname", this.firstname == null ? "" : this.firstname);
                dataSend.append("email", this.email == null ? "" : this.email);
                dataSend.append("phone1", this.phone1 == null ? "" : this.phone1);
                dataSend.append("phone2", this.phone2 == null ? "" : this.phone2);
                dataSend.append("comment", this.comment == null ? "" : this.comment);

                if(this.role == "admin") {
                    dataSend.append("role", this.selectedRole == null ? "" : this.selectedRole);
                }

                const url = this.$route.params.id ? `/agents/${this.$route.params.id}` : `/agents`;
                const request = await apiService.postFile(url, dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$router.push({ name: 'usersManagementDetail', params: { id: response.infos.id }, replace: true })
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;

                if (this.$route.params.id) {
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },

        async deleteHandler() {
            this.loadingDelete = true;
            try {
                const request = await apiService.delete(`/agents/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.$router.push({ name: "usersManagement", replace: true });
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};