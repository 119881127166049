import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import fileService from "@/services/fileService"
import { OPEN_SNACKBAR } from "@/utils/contants";
import { useAuthStore } from "@/stores/AuthStore";
import TicketHistoryDrawer from "@/components/online/tickets/drawer/TicketHistoryDrawer.vue"
import TicketTransfertDialog from "@/components/online/tickets/dialogs/TicketTransfertDialog.vue"
import TicketMailDialog from "@/components/online/tickets/dialogs/TicketMailDialog.vue"
import { storeToRefs } from "pinia";

import LoadingComponent from "@/components/general/loading/LoadingComponent.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        const { role } = storeToRefs(authStore)
        return { authStore, role };
    },

    components: {
        LoadingComponent,
        TicketHistoryDrawer,
        TicketTransfertDialog,
        TicketMailDialog,
    },

    props: {},

    watch: {
        "$i18n.locale": function () {
            this.title = this.$route.params.id ? this.$t('ticket["Mise à jour du ticket"]') : this.$t('ticket["Création d\'un ticket"]')
            this.getTitle()

            this.rules = {
                required: v => !!v || this.$t("form.validation.required"),
                email: v => /.+@.+\..+/.test(v) || this.$t("form.validation.email"),
                phone: v => /^[0-9]{10,}$/.test(v) || this.$t("form.validation.phone")
            }

            switch (this.ticketStatus) {
                case "waiting":
                    this.statusBtns = [
                        { label: this.$t('form.btn["Traité"]'), icon: "mdi-play", color: "blue-grey", status: "in progress" },
                        { label: this.$t('form.btn["Fermé"]'), icon: "mdi-lock", color: "red", status: "finished" },
                    ];
                    break;
                case "in progress":
                    this.statusBtns = [
                        { label: this.$t('form.btn["Pause"]'), icon: "mdi-pause-circle-outline", color: "blue-grey", status: "waiting" },
                        { label: this.$t('form.btn["Fermé"]'), icon: "mdi-lock", color: "red", status: "finished" },
                    ];
                    break;
                case "finished":
                    if (this.role == 'manager') {
                        this.statusBtns = [
                            { label: this.$t('form.btn["Réouvrir"]'), icon: "mdi-lock-open-variant-outline", color: "blue", status: "in progress" },
                        ];
                    }
                    break;
                default:
                    break;
            }
        },
        '$route'(to) {
            this.title = to.params.id ? this.$t('ticket["Mise à jour du ticket"]') : this.$t('ticket["Création d\'un ticket"]')
            this.ticketNumber = to.params.id;

            if (to.name == 'ticketNew') {
                this.disableGroupSelection = false;
                this.isDisabled = false;
                this.isDisabledBtn = false;
                this.demandeForm = [];
                this.annexeForm = [];
                this.group = null;
                this.retrieveTemplates();
            } else if (to.name == 'ticketDetail') {
                this.disableGroupSelection = true;
                this.isDisabled = true;
                this.isDisabledBtn = true;
                this.retrieveSingle();
            }
        },
        group(val) {
            if (!this.isDisabled && val != null) {
                this.availableMotif = [];
                val.motifs.forEach(el => {
                    this.availableMotif.push(el.motif)
                })

                this.retrieveTemplate(val.id)
            } else {
                this.demandeForm = [];
                this.annexeForm = [];
            }
        }
    },

    data() {
        return {
            rail: false,
            loading: false,
            dialogTransfert: false,
            dialogEmail: false,
            isDisabled: false,
            isDisabledBtn: false,
            isErrorMail: false,
            ticketNumber: this.$route.params.id,
            title: this.$route.params.id ? this.$t('ticket["Mise à jour du ticket"]') : this.$t('ticket["Création d\'un ticket"]'),

            disableGroupSelection: false,
            ticketStatus: null,

            group: null,
            groups: [],
            histories: [],
            demandeForm: [],
            annexeForm: [],

            availableMotif: [],

            rules: {
                required: v => !!v || this.$t("form.validation.required"),
                email: v => /.+@.+\..+/.test(v) || this.$t("form.validation.email"),
                phone: v => /^[0-9]{10,}$/.test(v) || this.$t("form.validation.phone")
            },

            statusBtns: [],
            sector: this.authStore.organisation.sector
        };
    },

    created() {
        if (!authTokenService.getToken()) return;

        if (!this.$route.params.id) {
            this.disableGroupSelection = false;
            this.isDisabledBtn = false;
            this.retrieveTemplates();
        }

        if (this.$route.params.id) {
            this.disableGroupSelection = true;
            this.isDisabled = true;
            this.isDisabledBtn = true;
            this.retrieveSingle();
        }
    },

    mounted() {

    },

    methods: {
        goBack() {
            this.$router.push({ name: 'tickets', replace: true });
        },

        getFieldText(item) {
            return `${item.lastname} ${item.firstname}`
        },

        getTitle() {
            let titleSector = {
                0: this.$t('ticket["Concession"]'),
                1: this.$t('ticket["Appelant"]'),
                2: this.$t('ticket["Service"]'),
                3: this.$t('ticket["Service du demandeur"]'),
            };
            return titleSector[this.authStore.organisation.sector]
        },

        enableEdition() {
            this.isDisabled = !this.isDisabled;
            this.isDisabledBtn = !this.isDisabledBtn;
        },

        toggleHistory() {
            this.rail = !this.rail
        },

        openTransfertDialog() {
            this.dialogTransfert = true
        },

        updateMoodEmot(field, item) {
            if (field.model != null && item.value == field.model.value) {
                return field.model = null;
            }
            field.model = item;
        },

        setModelValue(isMultiple, field, value, fromRequestForm) {
            if (isMultiple) {
                if (field.model == null) field.model = [];
                let removeElementIndex = field.model.findIndex(el => el.id == value.id);
                if (removeElementIndex != -1) field.model.splice(removeElementIndex, 1)
                else field.model.push(value)
            } else {
                field.model = value
            }

            if (fromRequestForm) {
                this.setReasonsFromTemplate(value, field)
            }
        },

        downloadScreenshots(values) {
            values.forEach(el => {
                let filename = `swilk_${el.name.replaceAll(' ', '_')}`
                const url = window.URL.createObjectURL(el);
                const link = document.createElement("a");
                link.href = url;
                link.style = "display: none;"
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },

        async setReasonsFromTemplate(currentValues, field) {
            if (field.properties.dependency == "group") {
                let reasonField = this.demandeForm.find(el => el.properties.dependency == "reason");
                if (reasonField) {
                    reasonField.model = null;
                    reasonField.values = [];
                }

                const subMotif = this.demandeForm.find(el => el.properties.dependency == "subReason");
                if (subMotif) {
                    subMotif.model = null;
                    subMotif.values = []
                }

                const sector = this.demandeForm.find(el => el.properties.dependency == "subGroup");
                if (sector) {
                    sector.model = null;
                    sector.values = []
                }
            }

            if (field.properties.dependency == "subGroup") {
                const collaborateurField = this.demandeForm.find(el => el.properties.dependency == "agent");
                if (collaborateurField) {
                    collaborateurField.model = null;
                    collaborateurField.values = [];

                    field.model.agents.forEach(el => {
                        el.agent.label = `${el.agent.lastname} ${el.agent.firstname}`
                        collaborateurField.values.push(el.agent)
                    })
                }
            }

            if (field.properties.dependency == "group" && this.demandeForm.find(el => el.properties.dependency == "subGroup")) {
                const subService = this.demandeForm.find(el => el.properties.dependency == "subGroup");
                const request = await apiService.get(`/backoffice/groups?parent=${currentValues.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                subService.model = null
                subService.values = response.data
            }

            if (field.properties.dependency == "group" && this.demandeForm.find(el => el.properties.dependency == "subGroup")) {
                const sector = this.demandeForm.find(el => el.properties.dependency == "subGroup");
                const request = await apiService.get(`/backoffice/groups?parent=${currentValues.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                sector.model = null
                sector.values = response.data
            }

            if (field.properties.dependency == "reason" && this.demandeForm.find(el => el.properties.dependency == "subReason")) {
                const subMotif = this.demandeForm.find(el => el.properties.dependency == "subReason");
                const request = await apiService.get(`/backoffice/motifs?parent=${currentValues.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                subMotif.model = null;
                subMotif.values = response.data
            }

            if (currentValues.motifs) {
                let availableReasonFromTemplate = [];
                currentValues.motifs.forEach(el => {
                    availableReasonFromTemplate.push(el.motif)
                })
                let reasonField = this.demandeForm.find(el => el.properties.dependency == "reason");
                if (reasonField) reasonField.values = availableReasonFromTemplate;
            }
        },

        async retrieveTemplates() {
            try {
                let url = "/form-templates";
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                const requestGroups = [];
                response.data.forEach(el => {
                    requestGroups.push(el.group)
                })
                this.groups = requestGroups;
            } catch (error) {
                this.groups = []
            }
        },

        async retrieveTemplate(groupId) {
            this.loading = true;
            try {
                let availableGroupFromTemplate = [];
                const request = await apiService.get(`/form-templates?group=${groupId}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                if (this.sector == 0 || this.sector == 1) {
                    const request2 = await apiService.get(`/backoffice/groups?parent=${groupId}`);
                    const response2 = await request2.json();
                    if (!request2.ok) throw new Error(response2.message);
                    availableGroupFromTemplate = response2.data
                }

                response.data.annexes.forEach(field => {
                    if (field.type == "select") {
                        if (field.distant.fromServer && field.distant.url != "") {
                            this.fetchDataForField(field.distant.url, field)
                        }
                    }
                })

                response.data.structures.forEach(field => {
                    if (field.type == "select") {
                        if (field.distant.fromServer && field.distant.url != "") {
                            this.fetchDataForField(field.distant.url, field)
                        }

                        if (field.properties.dependency == "group") {
                            field.values = availableGroupFromTemplate;
                        }

                        if (field.properties.dependency == "agent") {
                            let agents = []
                            this.group.agents.forEach(el => {
                                el.agent.label = `${el.agent.lastname} ${el.agent.firstname}`
                                agents.push(el.agent)
                            })
                            field.values = agents;
                        }
                    }
                })

                let motifField = response.data.structures.find(field => field.properties.dependency == 'reason');
                if (motifField) motifField.values = this.availableMotif;

                if (this.authStore.role == "agent") {
                    let tagElement = response.data.annexes.find(el => el.label == "Tags");
                    if (tagElement) tagElement.visible = false;
                } else {
                    let tagElement = response.data.annexes.find(el => el.label == "Tags");
                    if (tagElement) tagElement.visible = true;
                }

                let feedItself = response.data.structures.filter(field => field.properties.autocomplete);
                let feedItselfFirstName = feedItself.find(field => field.properties.field == "firstname" && field.modal == null)
                let feedItselfLastName = feedItself.find(field => field.properties.field == "lastname" && field.modal == null)

                if (feedItselfFirstName) feedItselfFirstName.model = this.authStore.firstname
                if (feedItselfLastName) feedItselfLastName.model = this.authStore.lastname

                let authorizedGroups = response.data.structures.filter(field => field.properties.authorized_groups && field.properties.authorized_groups.length > 0);
                if (authorizedGroups) {
                    authorizedGroups.forEach(field => {
                        this.authStore.groups.forEach(group => {
                            let lowercaseGroup = group.toLowerCase()
                            if (field.properties.authorized_groups.includes(lowercaseGroup)) {
                                field.properties.disabled = false
                            }
                        })
                    })
                }
                
                setTimeout(() => {
                    this.demandeForm = response.data.structures;
                    this.annexeForm = response.data.annexes;
                    this.loading = false;
                }, 2000);
            } catch (error) {
                this.demandeForm = [];
                this.annexeForm = [];
                this.loading = false;
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async fetchDataForField(url, field) {
            try {
                const request = await apiService.get(url);
                const response = await request.json();
                if (!request.ok) throw new Error();
                field.values = response.data;
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async updateStatusHandler(status) {
            this.isDisabled = true;
            this.isDisabledBtn = true;

            try {
                const request = await apiService.post(`/tickets/${this.$route.params.id}/status`, { status });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })
                this.retrieveSingle();
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async retrieveSingle() {
            this.dialogEmail = false;
            this.statusBtns = [];
            this.loading = true;
            try {
                const request = await apiService.get(`/tickets/${this.$route.params.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                if (this.sector == 0 || this.sector == 1) {
                    if (response.data.group.parent.parent != null) {
                        this.group = response.data.group.parent.parent;
                    } else {
                        this.group = response.data.group.parent;
                    }
                } else {
                    this.group = response.data.group;
                }

                this.ticketStatus = response.data.ticketStatus;
                this.histories = response.data.histories;

                this.availableMotif = [];
                this.group.motifs.forEach(el => {
                    this.availableMotif.push(el.motif)
                })

                let motifField = response.data.structures.find(field => field.properties.dependency == 'reason');
                if (motifField) motifField.values = this.availableMotif;

                if (this.authStore.role == "agent") {
                    let tagElement = response.data.annexeStructure.find(el => el.label == "Tags");
                    if (tagElement) tagElement.visible = false;
                } else {
                    let tagElement = response.data.annexeStructure.find(el => el.label == "Tags");
                    if (tagElement) tagElement.visible = true;
                }

                let fileUpload = response.data.structures.find(el => el.type == 'files')
                if(fileUpload) {
                    for (let i = 0; i < fileUpload.values.length; i++) {
                        const base64 = fileUpload.values[i];
                        fileUpload.model.push(fileService.base64toFile(base64, `swilk_ticket${this.$route.params.id}_${i}`))
                    }
                }

                setTimeout(() => {
                    this.demandeForm = response.data.structures;
                    this.annexeForm = response.data.annexeStructure;
                    this.loading = false;
                }, 1000);

                switch (response.data.ticketStatus) {
                    case "waiting":
                        this.statusBtns = [
                            { label: this.$t('form.btn["Traité"]'), icon: "mdi-play", color: "blue-grey", status: "in progress" },
                            { label: this.$t('form.btn["Fermé"]'), icon: "mdi-lock", color: "red", status: "finished" },
                        ];
                        break;
                    case "in progress":
                        this.statusBtns = [
                            { label: this.$t('form.btn["Pause"]'), icon: "mdi-pause-circle-outline", color: "blue-grey", status: "waiting" },
                            { label: this.$t('form.btn["Fermé"]'), icon: "mdi-lock", color: "red", status: "finished" },
                        ];
                        break;
                    case "finished":
                        if (this.role == 'manager') {
                            this.statusBtns = [
                                { label: this.$t('form.btn["Réouvrir"]'), icon: "mdi-lock-open-variant-outline", color: "blue", status: "in progress" },
                            ];
                        }
                        break;
                    default:
                        break;
                }
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })

                this.loading = false;
                this.$router.push({ name: 'tickets', replace: true });
            }
        },

        async submitHandler() {
            if (this.ticketStatus == 'finished') return;

            try {
                const { valid } = await this.$refs.form.validate();
                if (!valid) throw new Error("Des champs sont manquants ou invalides");

                this.loading = true;
                this.isDisabledBtn = true;

                let fileUpload = this.demandeForm.find(el => el.type == 'files')
                fileUpload.values = [];

                if(fileUpload) {
                    if(fileUpload.model != null) {
                        for (let i = 0; i < fileUpload.model.length; i++) {
                            const element = fileUpload.model[i];
                            const base64 = await fileService.convertBase64(element);
                            fileUpload.values.push(base64)
                        }
        
                        fileUpload.model = []
                    }
                }

                let dataSend = {
                    group: this.group.id,
                    structures: JSON.stringify(this.demandeForm),
                    annexesStructure: JSON.stringify(this.annexeForm),
                };

                const url = this.$route.params.id ? `/tickets/${this.$route.params.id}` : `/tickets`;
                const request = await apiService.post(url, dataSend);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                eventBus.$emit(OPEN_SNACKBAR, { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.disableGroupSelection = true;
                    this.$router.push({ name: 'ticketDetail', params: { id: response.infos.id }, replace: true })
                } else {
                    let fileUpload = this.demandeForm.find(el => el.type == 'files')
                    if(fileUpload) {
                        for (let i = 0; i < fileUpload.values.length; i++) {
                            const base64 = fileUpload.values[i];
                            fileUpload.model.push(fileService.base64toFile(base64, `swilk_ticket${this.$route.params.id}_${i}`))
                        }
                    }
                }
            } catch (error) {
                if (this.$route.params.id) {
                    this.retrieveSingle();
                }
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;

                if (this.$route.params.id) {
                    this.disableGroupSelection = true;
                    this.isDisabledBtn = true;
                    this.isDisabled = true;
                } else {
                    this.isDisabledBtn = false;
                }
            }
        },
    },
};