import eventBus from "@/eventBus";
import apiService from "@/services/apiService";
import { OPEN_SNACKBAR } from "@/utils/contants";
import { useAuthStore } from "@/stores/AuthStore";
import { useTicketListConfigStore } from "@/stores/TicketListConfigStore";
import { storeToRefs } from "pinia";

import DeleteDialog from "@/components/general/dialogs/DeleteDialog.vue"
import TicketColumSchedulingDialog from "@/components/online/tickets/dialogs/TicketColumSchedulingDialog.vue"
import TicketColumsHideShowDialog from "@/components/online/tickets/dialogs/TicketColumsHideShowDialog.vue"
import authTokenService from "@/services/authTokenService";

export default {
    setup() {
        const authStore = useAuthStore();
        const ticketListConfigStore = useTicketListConfigStore();
        const { ticketListConfig } = storeToRefs(ticketListConfigStore)
        return { authStore, ticketListConfigStore, ticketListConfig };
    },

    components: {
        DeleteDialog,
        TicketColumSchedulingDialog,
        TicketColumsHideShowDialog,
    },

    props: {
        refreshList: {
            defaul: false,
        }
    },

    watch: {
        search(val) {
            if (val != null && val.trim() != "") {
                this.$router.push({ name: this.$route.name, query: { search: val } })
            } else {
                this.$router.push({ name: this.$route.name })
            }
        },
        refreshList(val) {
            if (val) {
                this.retrieveData();
            }
        },
        '$route'(to) {
            this.filterStatus = to.query.status ?? null;
            this.filterDate = to.query.date ?? null;
            this.filterPriority = to.query.priority ?? null;
            this.filterString = this.filterStringArray.length > 0 ? `&${this.filterStringArray.join("&")}` : "";
            this.retrieveData();
        },
        "$i18n.locale": function() {
            this.ticketListConfigStore.ticketListConfig.forEach(el => {
                el.title = this.$t(`datatable.header["${el.title}"]`)
            })
            this.items = [
                { title: this.$t('ticket["Afficher/cacher des colonnes"]'), type: 'positionning', value: true },
                { title: this.$t('ticket["Position des colonnes"]'), type: 'scheduling', value: true },
            ]
        }
    },

    data() {
        return {
            dialogScheduling: false,
            dialogColumnHideShow: false,

            loading: false,
            search: this.$route.query.search ?? null,
            selectedItem: null,

            tickets: [],
            selected: [],
            headers: this.ticketListConfig.filter(element => element.show),

            totalTickets: 0,
            totalHightPriorityTickets: 0,
            totalTodayTickets: 0,

            items: [
                { title: this.$t('ticket["Afficher/cacher des colonnes"]'), type: 'positionning', value: true },
                { title: this.$t('ticket["Position des colonnes"]'), type: 'scheduling', value: true },
            ],

            availableData: {
                availableColors: [],
                priorityColors: {
                    high: "",
                    medium: "",
                    low: "",
                    "very low": "",
                }
            },

            waitingTicketList: [],
            inProgressTicketList: [],

            filterStringArray: [],
            filterStatus: null,
            filterDate: null,
            filterPriority: null,
        };
    },

    created() {
        this.filterStatus = this.$route.query.status ?? null;
        this.filterDate = this.$route.query.date ?? null;
        this.filterPriority = this.$route.query.priority ?? null;

        if (this.filterStatus != null) this.filterStringArray.push(`status=${this.filterStatus}`);
        if (this.filterDate != null) this.filterStringArray.push(`date=${this.filterDate}`);
        if (this.filterPriority != null) this.filterStringArray.push(`priority=${this.filterPriority}`);
        this.filterString = this.filterStringArray.length > 0 ? `&${this.filterStringArray.join("&")}` : "";

        if(!authTokenService.getToken()) return;
        this.retrieveData();
        this.retrieveTicketsColors();
    },

    mounted() { },

    methods: {
        objectToString(object, string) {
            const temp = string.split(".");
            const getNestedObject = (nestedObj, pathArr) => {
                return pathArr.reduce((obj, key) =>
                    (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
            }

            return getNestedObject(object, temp);
        },

        actionHandler(type, value) {
            if (type == "scheduling") {
                this.dialogScheduling = value;
            }
            if (type == "positionning") {
                this.dialogColumnHideShow = value;
            }
        },

        getClassRow(item) {
            const moods = item.annexeStructureData.find(field => field.type == "moods");
            if (moods.model != null &&
                (
                    moods.model.label == 'insatisfait' ||
                    moods.model.label == 'tres insatisfait'
                )
            ) {
                return `bg-${this.availableData.priorityColors['high']}`
            } else {
                return `bg-${this.availableData.priorityColors[item.priority]}`
            }
        },

        datatableFilterWithSubject(value, query) {
            return value != null &&
                query != null &&
                typeof value === 'string' &&
                value.toString().indexOf(query) !== -1
        },

        filterHandler(type) {
            if (type == null) {
                this.filterStringArray = [];
                this.$router.push({ name: this.$route.name, replace: true })
                return;
            }

            let currentDate = new Date().toJSON().slice(0, 10);

            let availableFilter = {
                "today": `date=${currentDate}`,
                "high": `priority=high`,
                "in progress": `status=in progress`,
                "waiting": `status=waiting`,
            }

            if (this.filterStringArray.includes(availableFilter[type])) {
                let elementIndex = this.filterStringArray.findIndex(el => el == availableFilter[type]);
                this.filterStringArray.splice(elementIndex, 1);
            } else {
                if (type == "in progress") {
                    let elementIndex = this.filterStringArray.findIndex(el => el == availableFilter["waiting"]);
                    if (elementIndex != -1) this.filterStringArray.splice(elementIndex, 1);
                } else if (type == "waiting") {
                    let elementIndex = this.filterStringArray.findIndex(el => el == availableFilter["in progress"]);
                    if (elementIndex != -1) this.filterStringArray.splice(elementIndex, 1);
                }

                this.filterStringArray.push(availableFilter[type])
            }

            let query = {}

            this.filterStringArray.forEach(el => {
                let strings = el.split("=");
                query[strings[0]] = strings[1];
            })

            this.$router.push({ name: this.$route.name, query, replace: true })
        },

        async retrieveTicketsColors() {
            try {
                const request = await apiService.get("/interfaces");
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.availableData = response.data;
            } catch (error) {
                this.availableColors = [];
            }
        },

        async retrieveData() {
            this.loading = true;
            try {
                const request = await apiService.get(`/tickets?filter=open${this.filterString}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                const highInsatisfaction = [];
                const mediumInsatisfaction = [];

                response.data.forEach(element => {
                    let requester = ""
                    const textFields = element.structuresData.filter(field => field.type == "text");
                    textFields.forEach(field => {
                        if ((field.label == "Nom, prenom parent" || field.label == "Nom, prénom parent") && field.model != null) requester += field.model;
                        if ((field.label == "Nom" || field.label == "Nom") && field.model != null) requester += field.model;
                        if ((field.label == "Prénom" || field.label == "Prenom") && field.model != null) requester += " " + field.model;
                        if ((field.label == "Société" || field.label == "Societe") && field.model != null) requester += " - " + field.model;
                    })

                    element.client = requester;
                    element.number = `Ticket#${element.id}`

                    const subMotif = element.structuresData.find(field => field.label == "Sous-motif");
                    const motif = element.structuresData.find(field => field.label == "Motif");
                    if (motif && !subMotif) element.motif = motif.model.label;
                    if (motif && subMotif) element.motif = subMotif.model.label;

                    const moods = element.annexeStructureData.find(field => field.type == "moods");
                    if (moods && moods.model && moods.model.label == "tres insatisfait") highInsatisfaction.push(element)
                    if (moods && moods.model  && moods.model.label == "insatisfait") mediumInsatisfaction.push(element);
                });

                const availablePriorities = ['high', 'low', 'medium', 'very low'];
                const hightPriority = response.data.filter(element => element.priority == availablePriorities[0]);
                let hightPriority3 = highInsatisfaction.concat(hightPriority, mediumInsatisfaction);

                let hightPriorityUnique = hightPriority3.filter((c, index) => {
                    return hightPriority3.indexOf(c) === index;
                });

                const hightPriority2 = hightPriorityUnique

                const mediumPriority = response.data.filter(element => element.priority == availablePriorities[2]);
                const lowPriority = response.data.filter(element => element.priority == availablePriorities[1]);
                const weakPriority = response.data.filter(element => element.priority == availablePriorities[3]);
                let allData = hightPriority2.concat(mediumPriority, lowPriority, weakPriority);

                let uniqueArray = allData.filter((c, index) => {
                    return allData.indexOf(c) === index;
                });

                let availableData = uniqueArray;

                this.waitingTicketList = availableData.filter(element => element.ticketStatus == 'waiting');
                this.inProgressTicketList = availableData.filter(element => element.ticketStatus == 'in progress');

                let currentDate = new Date();
                let ticketToday = 0;

                availableData.forEach(element => {
                    let ticketDate = new Date(element.createdAt);
                    if (currentDate.getDate() == ticketDate.getDate() &&
                        currentDate.getMonth() == ticketDate.getMonth() &&
                        currentDate.getFullYear() == ticketDate.getFullYear()
                    ) {
                        ticketToday += 1;
                    }
                })
                this.totalTickets = availableData.length;
                this.totalHightPriorityTickets = hightPriority2.length;
                this.totalTodayTickets = ticketToday;

                if(this.authStore.organisation.sector == 3) {
                    let clientTitle = this.headers.find(el => el.key == 'client') 
                    clientTitle.title = "Description"
                    
                    availableData.forEach(el => {
                        const description = el.structuresData.find(field => field.type == 'textarea' && field.label == 'Description du problème')
                        el.client = `${description.model.substring(0, 80)}${description.model.length > 80 ? '...' : ''}`
                    })
                }

                this.tickets = availableData;
                this.$emit('stopRefresh');
            } catch (error) {
                this.tickets = [];
            } finally {
                this.loading = false;
            }
        },

        editItem(item) {
            this.$router.push({ name: "ticketDetail", params: { id: item.id } })
        },

        async updateTicketColumns(reOrderedColumns) {
            this.headers = reOrderedColumns.filter(element => element.show)

            try {
                const request = await apiService.post(`/account/tickets-columns`, { data: JSON.stringify(reOrderedColumns) });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
            } catch (error) {
                eventBus.$emit(OPEN_SNACKBAR, {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    },
};