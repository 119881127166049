<template>
  <div style="margin-top: -5px" class="">
    <DeleteDialog
      v-model:dialog="dialogDelete"
      :title="$t(`admin[&quot;Supprimer un temps de traitement&quot;]`)"
      :text="
        $t(
          `admin[&quot;Voulez-vous vraiment supprimer ce temps de traitement ?&quot;]`
        )
      "
      :loading="loadingDelete"
      @confirmDelete="deleteHandler"
    />

    <ProcessingTimeActionsDialog
      v-if="!loading"
      v-model:dialog="dialogActions"
      :isDisabled="isDisabled"
      :actions="actions"
      @refresh="retrieveSingle"
      @updateActions="updateActions"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex align-center flex-wrap">
        <v-icon size="large" class="mr-4" color="teal-darken-1" @click="goBack">
          mdi-backspace
        </v-icon>
        <v-card
          width="fit-content"
          elevation="0"
          border=""
          class="py-2 px-4 d-flex align-center"
          v-if="selectedStatus != null"
        >
          <v-row no-gutters v-if="!loading">
            <v-col class="d-flex align-center justify-center">
              <v-chip
                label
                density="compact"
                color="red"
                class="mr-2 px-2 py-3"
              >
                {{ $t(`admin["${selectedStatus.label}"]`) }}
              </v-chip>
            </v-col>
          </v-row>
          <div v-else>
            <v-progress-circular
              :size="15"
              :width="2"
              color="grey"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>

        <div v-if="frontInfo != null">
          <v-btn
            color="red"
            class="ma-1 ml-lg-3"
            size="small"
            @click="dialogDelete = !dialogDelete"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-delete</v-icon>
            {{ $t('form.btn["Supprimer"]') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-lg-end justify-md-end align-center">
        <v-btn
          v-if="frontInfo && !loading"
          size="small"
          color="teal-darken-1"
          @click="enableEdition"
          class="mx-5"
        >
          <div v-if="isDisabledBtn">
            <v-icon size="20" class="mr-1">mdi-pencil </v-icon>
            {{ $t('form.btn["Modifier"]') }}
          </div>
          <div v-else>
            <v-icon size="20" class="mr-1">mdi-close </v-icon>
            {{ $t('form.btn["Annuler"]') }}
          </div>
        </v-btn>
        <v-btn
          size="small"
          color="teal-darken-1"
          :loading="loading"
          :disabled="isDisabledBtn"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t('form.btn["Sauvegarder"]') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>

    <div class="py-lg-4 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col
          cols="10"
          lg="10"
          md="10"
          sm="10"
          class="d-flex align-center pr-2"
        >
          <h2 v-if="!loading" class="mr-5">
            {{ frontInfo == null ? $t(`admin["${title}"]`) : frontInfo }}
          </h2>
          <v-btn
            size="small"
            class="ma-2 d-flex"
            :loading="loading"
            @click="dialogActions = true"
            color="teal-darken-1"
          >
            <v-icon v-if="!isDisabled" class="mr-2">mdi-pencil</v-icon>
            {{ $t('dialog["Actions"]') }}
          </v-btn>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="5"
            v-if="frontInfo != null"
            class="d-flex"
          >
            <v-btn
              v-if="status != null"
              size="small"
              class="ma-2 d-flex"
              :color="status.color"
              :loading="loading"
              @click="updateStatusHandler(status.value)"
            >
              <v-icon size="20" class="mr-2">{{ status.icon }}</v-icon>
              <p>{{ $t(`admin["${status.label}"]`) }}</p>
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>

    <v-form ref="form" class="ticket__form_height">
      <div class="py-lg-1 px-lg-8 py-md-2 px-md-5 pa-4">
        <v-row no-gutters>
          <v-col cols="12" lg="10" md="10" sm="12" class="my-2">
            <h3 class="font-weight-medium text-body-1 mt-1">
              {{ $t(`form.description["Temps de traitement"]`) }}
            </h3>
            <p class="text-caption mb-lg-6 mb-md-8 mb-3 mt-1">
              {{
                $t(
                  `form.description["Saississez les informations du temps de traitement"]`
                )
              }}
            </p>

            <v-row class="my-3" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="name" class="font-weight-medium text-caption">
                  {{ $t('form.label["Libellé"]') }}
                </label>
                <v-text-field
                  id="name"
                  v-model="name"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-3" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label
                  for="selectedApplicationCase"
                  class="font-weight-medium text-caption"
                >
                  {{ $t('form.label["Cas d\'application"]') }}
                </label>
                <v-select
                  id="selectedApplicationCase"
                  v-model="selectedApplicationCase"
                  :items="applicationCases"
                  item-title="label"
                  item-value="value"
                  return-object
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="my-3" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="frequency" class="font-weight-medium text-caption">
                  {{ $t('form.label["Temps"]') }}*
                </label>
                <v-text-field
                  id="frequency"
                  v-model="frequency"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                  type="number"
                  min="0"
                ></v-text-field>

                <p class="text-caption text-grey">
                  *{{ $t('admin["Temps de traitement en jours"]') }}
                </p>
              </v-col>
            </v-row>

            <v-row class="my-3" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="note" class="font-weight-medium text-caption">
                  {{ $t('form.label["Note"]') }}
                </label>
                <v-textarea
                  id="note"
                  v-model="note"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :readonly="isDisabled"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script src="./js/processingTimeForm.js"></script>