<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h3 class="pt-1">
              {{ $t('admin["Tutoriel"]') }}
            </h3>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="30" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5 mb-5" style="height: 400px; overflow-y: scroll;">
        <p class="text-justify mb-4" style="font-size: 15px">
          {{
            $t(
              'admin.tutoriel["Vous devez suivre minutieusement ces différentes étapes afin que le formulaire puisse se charger automatiquement à condition que le code d\'intégration soit valide."]'
            )
          }}
        </p>

        <h4 class="mb-2">Etape 1</h4>
        <p class="mb-4" style="font-size: 15px">
          <v-code>
            -
            {{
              $t(
                `admin.tutoriel["Pour générer le code d'intégration, il faut cliquer sur le bouton 'Nouveau'."]`
              )
            }}
            <br />
            -
            {{
              $t(
                `admin.tutoriel["Puis vous devez choisir parmi les templates disponibles et la source des tickets."]`
              )
            }}
            <br />
            -
            {{
              $t(
                `admin.tutoriel["Et ensuite vous devez cliquer sur le bouton 'Générer'"]`
              )
            }}.
          </v-code>
        </p>

        <h4 class="mb-2">Etape 2</h4>
        <p class="mb-4" style="font-size: 15px">
          <v-code>
            {{
              $t(
                `admin.tutoriel["Copier/coller le script suivant avant la balise de fermeture du 'head' de votre page HTML"]`
              )
            }}
            : <br />
            <span class="text-blue font-weight-medium">
              &lt;script src="{{ localUrl }}/scripts/swilk_integration.min.js"&gt;&lt;/script&gt;
            </span>
          </v-code>
          <a
            href="https://developer.mozilla.org/fr/docs/Web/HTML/Element/head"
            class="mt-1"
            >{{ $t(`admin.tutoriel["Ressource"]`) }}</a
          >
        </p>

        <h4 class="mb-2">Etape 3</h4>
        <p class="mb-4" style="font-size: 15px">
          <v-code>
            -
            {{
              $t(
                'admin.tutoriel["Vous devez ajouter la balise ci-dessous dans votre page HTML en modifiant la valeur"]'
              )
            }}
            <span class="font-weight-bold"
              >"{{ $t('admin.tutoriel["embed_code"]') }}"</span
            >
            {{
              $t(
                `admin.tutoriel["par le code d'intégration générer à l'étape 1"]`
              )
            }}
            <br />
            <span class="text-blue font-weight-medium mb-4">
              &lt;div id="swilk_integration" data-embed="<span
                class="font-weight-bold"
                >embed_code</span
              >"&gt; &lt;/div&gt;
            </span>
          </v-code>
        </p>

        <h4 class="mb-2">{{ $t('admin.tutoriel["Exemple"]') }}</h4>
        <p class="mb-4" style="font-size: 15px">
          <v-code>
            <span class="text-red"> &lt;!DOCTYPE html&gt; </span> <br />
            <span class="font-weight-medium"> &lt;html lang="en"&gt; </span> <br />
            <span class="font-weight-medium"> &lt;head&gt; </span> <br />

            &nbsp;&nbsp; &lt;title&gt;My website&lt;/title&gt; <br />
            &nbsp; &nbsp; &lt;script src="{{ localUrl }}/scripts/swilk_integration.min.js"&gt;&lt;/script&gt;<br />

            <span class="font-weight-medium"> &lt;/head&gt; </span><br />

            <span class="font-weight-medium"> &lt;body&gt;</span> <br />
            &nbsp;&nbsp; &lt;div id="swilk_integration" data-embed="Kr52Tqft1682084058id2zrZ3StYsLryz"&gt;&lt;/div&gt;
            <br />
            <span class="font-weight-medium"> &lt;/body&gt;</span> <br />
            <span class="font-weight-medium"> &lt;/html&gt; </span> <br />
          </v-code>
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script src="./js/templateIntegrationTuto.js"></script>