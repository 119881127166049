const fileService = {
    convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    },
    base64toFile(base64Data, fileName) {
        const type      = base64Data.split('data:')[1].split(';')[0]
        const extension = { "image/png": ".png", "image/jpeg": ".jpeg", "image/bmp": ".bmp",} 
        const finalFileName = `${fileName}${extension[type]}`

        const splitIndex = base64Data.indexOf(',');
        const base64String = base64Data.substr(splitIndex + 1);
    
        const byteCharacters = atob(base64String);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
    
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, { type: type });
        const file = new File([blob], finalFileName, { type: type });

        return file;
    }
}

export default fileService;