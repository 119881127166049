<template>
  <v-chip color="red" class="ma-1"  @click="setTagFilter('group', { id: node.id, label: node.label })">
    {{ node.label }}
  </v-chip>
</template>

<script>
import eventBus from '@/eventBus';
export default {
  name: "ContactBooksGroup",
  props: {
    node: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    setTagFilter(type, item) {
      eventBus.$emit("setTagFilter", {type, item})
    }
  },
};
</script>
