<template>
  <v-dialog v-model="dialog" persistent :width="cardWidth">
    <v-card>
      <v-card-item>
        <v-row no-gutters>
          <v-col cols="10">
            <h2 class="pt-1">
              {{ $t(`form.description["Propriétés d'un champ"]`) }}
            </h2>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-icon size="35" color="red" @click="close">mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-item>

      <v-divider class="mb-5 mt-2"></v-divider>

      <div class="px-5" style="height: 320px; overflow: scroll">
        <v-row class="my-1" no-gutters>
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Libellé"]') }}
            </label>
            <v-text-field
              v-model="fieldEdit.label"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :disabled="isDisabled"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-1" no-gutters>
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Placeholder"]') }}
            </label>
            <v-text-field
              v-model="fieldEdit.properties.placeholder"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :disabled="isDisabled"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider class="mt-4"></v-divider>

        <v-row class="mt-2" no-gutters>
          <v-col
            cols="12"
            lg="10"
            md="12"
            class="d-flex justify-space-between flex-wrap"
          >
            <div>
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Visibilité"]') }}
              </label>
              <v-switch
                v-model="fieldEdit.visible"
                :label="
                  fieldEdit.visible
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
            <div>
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Requis"]') }}
              </label>
              <v-switch
                v-model="fieldEdit.properties.required"
                :label="
                  fieldEdit.properties.required
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
            <div v-if="fieldEdit.type == 'select'">
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Objet"]') }}
              </label>
              <v-switch
                v-model="fieldEdit.properties.object"
                :label="
                  fieldEdit.properties.object
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-4" v-if="fieldEdit.properties.autocomplete != undefined"></v-divider>

        <v-row class="mt-2" no-gutters>
          <v-col
            cols="12"
            lg="10"
            md="12"
            class="d-flex justify-space-between flex-wrap"
          >
            <div v-if="fieldEdit.properties.autocomplete != undefined">
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Auto-alimenter"]') }}
              </label>
              <v-switch
                v-model="fieldEdit.properties.autocomplete"
                :label="
                  fieldEdit.properties.autocomplete
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
            <div v-if="fieldEdit.properties.disabled != undefined">
              <label class="font-weight-medium text-caption">
                {{ $t('form.label["Désactiver"]') }}
              </label>
              <v-switch
                v-model="fieldEdit.properties.disabled"
                :label="
                  fieldEdit.properties.disabled
                    ? this.$t(`form.label[&quot;Oui&quot;]`)
                    : this.$t(`form.label[&quot;Non&quot;]`)
                "
                hide-details=""
                color="teal-darken-1"
                :disabled="isDisabled"
                density="compact"
              ></v-switch>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-4"></v-divider>

        <v-row class="my-1" no-gutters v-if="fieldEdit.properties.object">
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Champs afficher"]') }} (ex: label)
            </label>
            <v-text-field
              v-model="fieldEdit.properties.title"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :disabled="isDisabled"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-1" no-gutters v-if="fieldEdit.properties.object">
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Valeur"]') }}
            </label>
            <v-text-field
              v-model="fieldEdit.properties.value"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :disabled="isDisabled"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="my-1" no-gutters v-if="fieldEdit.properties.object">
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Sélection multiple"]') }}
            </label>
            <v-switch
              v-model="fieldEdit.properties.multiple"
              :label="
                fieldEdit.properties.multiple
                  ? this.$t(`form.label[&quot;Oui&quot;]`)
                  : this.$t(`form.label[&quot;Non&quot;]`)
              "
              hide-details=""
              color="teal-darken-1"
              :disabled="isDisabled"
              density="compact"
            ></v-switch>
          </v-col>
        </v-row>

        <v-divider class="mt-4" v-if="fieldEdit.properties.object"></v-divider>

        <v-row class="mt-2" no-gutters v-if="fieldEdit.distant">
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["Récupérer des valeurs depuis le serveur"]') }}
            </label>
            <v-switch
              v-model="fieldEdit.distant.fromServer"
              :label="
                fieldEdit.distant.fromServer
                  ? this.$t(`form.label[&quot;Oui&quot;]`)
                  : this.$t(`form.label[&quot;Non&quot;]`)
              "
              hide-details=""
              color="teal-darken-1"
              :disabled="isDisabled"
              density="compact"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row
          class="my-1"
          no-gutters
          v-if="fieldEdit.distant && fieldEdit.distant.fromServer"
        >
          <v-col cols="12" lg="10" md="12">
            <label class="font-weight-medium text-caption">
              {{ $t('form.label["URL"]') }} (ex: /tags)
            </label>
            <v-text-field
              v-model="fieldEdit.distant.url"
              variant="outlined"
              density="compact"
              hide-details=""
              class="mt-1"
              :disabled="isDisabled"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>

      <v-divider class="mt-6"></v-divider>

      <v-card-actions class="py-5 mr-3">
        <v-spacer></v-spacer>
        <v-btn
          size="default"
          class="mr-4 px-5"
          color="grey"
          variant="outlined"
          @click="close"
        >
          <span class="text-black">
            {{ $t('form.btn["Annuler"]') }}
          </span>
        </v-btn>
        <v-btn
          size="default"
          class="px-5"
          color="teal-darken-1"
          variant="flat"
          :disabled="isDisabled"
          @click="actionHandler"
        >
          {{ $t('form.btn["Valider"]') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="./js/ticketTemplateFieldDialog.js"></script>