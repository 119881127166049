<template>
  <div style="margin-top: -5px" class="">
    <DeleteDialog
      v-model:dialog="dialogDelete"
      :title='$t(`manager.userManagement["Supprimer un agent"]`)'
      :text='$t(`manager.userManagement["Voulez-vous vraiment supprimer cet agent ?"]`)'
      :loading="loadingDelete"
      @confirmDelete="deleteHandler"
    />

    <UserManagementDisableAccountDialog
      v-if="!loading"
      v-model:dialog="dialogDisable"
      :isDisabled="isDisabled"
      :isActive="isActive"
      @refresh="retrieveSingle"
    />

    <UserManagementGroupAssignmentDialog
      v-if="!loading"
      v-model:dialog="dialogGroup"
      :isDisabled="isDisabled"
      :groups="groups"
      @refresh="retrieveSingle"
    />

    <UserManagementJobAssignmentDialog
      v-if="!loading"
      v-model:dialog="dialogJob"
      :isDisabled="isDisabled"
      :userJob="userJob"
      @refresh="retrieveSingle"
    />

    <v-row class="bg-brown-lighten-5 pt-2 px-4">
      <v-col class="d-flex align-center flex-wrap">
        <v-icon size="large" class="mr-4" color="teal-darken-1" @click="goBack">
          mdi-backspace
        </v-icon>

        <div v-if="userIdentity != null">
          <v-btn
            color="red"
            class="ma-1 ml-lg-3"
            size="small"
            @click="dialogDelete = !dialogDelete"
            :disabled="loading"
          >
            <v-icon class="mr-1">mdi-delete</v-icon>
            {{ $t('form.btn["Supprimer"]') }}
          </v-btn>
          <v-btn
            color="grey-darken-1"
            class="ma-1 ml-lg-3"
            size="small"
            @click="dialogDisable = !dialogDisable"
            :disabled="loading"
          >
            <v-icon class="mr-1" size="20">mdi-account-cancel</v-icon>
            {{ $t('form.btn["Désactiver"]') }}
          </v-btn>
        </div>
      </v-col>
      <v-col class="d-flex justify-lg-end justify-md-end align-center">
        <v-btn
          v-if="userIdentity && !loading"
          size="small"
          color="teal-darken-1"
          @click="enableEdition"
          class="mx-5"
        >
          <div v-if="isDisabledBtn">
            <v-icon size="20" class="mr-1">mdi-pencil </v-icon>
            {{ $t('form.btn["Modifier"]') }}
          </div>
          <div v-else>
            <v-icon size="20" class="mr-1">mdi-close </v-icon>
            {{ $t('form.btn["Annuler"]') }}
          </div>
        </v-btn>
        <v-btn
          size="small"
          color="teal-darken-1"
          :loading="loading"
          :disabled="isDisabledBtn"
          @click="submitHandler"
        >
          <v-icon size="25" class="mr-2">mdi-content-save</v-icon>
          {{ $t('form.btn["Sauvegarder"]') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-3"></v-divider>
    <div class="py-lg-4 px-lg-8 py-md-4 px-md-5 pa-4">
      <v-row no-gutters>
        <v-col cols="8" lg="3" md="5" sm="7" class="pr-2">
          <h2>{{ userIdentity == null ? title : userIdentity }}</h2>

          <div class="mt-3" v-if="userIdentity != null">
            <div class="d-flex">
              <div class="mr-2">
                <v-btn
                  size="small"
                  color="grey"
                  @click="dialogJob = !dialogJob"
                >
                  <v-icon v-if="!isDisabled" size="20" class="mr-1">
                    mdi-pencil
                  </v-icon>
                  <p class="mr-2">
                    {{ $t('form.btn["Fonctions"]') }}
                  </p>
                </v-btn>
              </div>
              <div>
                <v-btn
                  size="small"
                  color="grey"
                  @click="dialogGroup = !dialogGroup"
                >
                  <v-icon v-if="!isDisabled" size="20" class="mr-1">
                    mdi-pencil
                  </v-icon>
                  <p class="mr-2">
                     {{ $t('form.btn["Groupes"]') }}
                  </p>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider></v-divider>

    <v-form ref="form" class="ticket__form_height">
      <div class="py-lg-1 px-lg-8 py-md-2 px-md-5 pa-4">
        <v-row no-gutters>
          <v-col cols="12" lg="" md="" sm="12" class="px-2">
            <h3 class="font-weight-medium text-body-1 mt-2">
              {{ $t('form.description["Générale"]') }}
            </h3>
            <p class="text-caption mb-lg-6 mb-md-5 mb-3 mt-1">
              {{ $t('form.description["Saississez les informations générales"]') }}
            </p>

            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <div style="position: relative" class="d-flex justify-center">
                  <v-avatar
                    size="90"
                    color="grey"
                    @click="isDisabled ? null : createDynamicInputFile()"
                    :style="isDisabled ? '' : 'cursor: pointer'"
                  >
                    <v-img
                      v-if="uploadImage != null"
                      :src="preview()"
                      cover
                    ></v-img>
                    <v-img
                      v-else
                      :src="getFilePath(avatar, true)"
                      alt="avatar"
                      cover
                    ></v-img>
                  </v-avatar>
                  <v-icon
                    v-if="!isDisabled"
                    @click="createDynamicInputFile"
                    color="black"
                    :style="isDisabled ? '' : 'cursor: pointer'"
                    style="
                      position: absolute;
                      bottom: -8px;
                      z-index: 800;
                      left: 50%;
                      transform: translate(-50%);
                    "
                    >mdi-camera</v-icon
                  >
                </div>
              </v-col>
            </v-row>

            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="login" class="font-weight-medium text-caption">
                  {{ $t('form.label["Identifiant"]')}}<span class="text-red">*</span>
                </label>
                <v-text-field
                  id="login"
                  v-model="login"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="lastname" class="font-weight-medium text-caption">
                  {{ $t('form.label["Nom de famille"]')}}<span class="text-red">*</span>
                </label>
                <v-text-field
                  id="lastname"
                  v-model="lastname"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="firstname" class="font-weight-medium text-caption">
                  {{ $t('form.label["Prénom"]')}}<span class="text-red">*</span>
                </label>
                <v-text-field
                  id="firstname"
                  v-model="firstname"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="email" class="font-weight-medium text-caption">
                  {{ $t('form.label["Adresse email"]')}}<span class="text-red">*</span>
                </label>
                <v-text-field
                  id="email"
                  v-model="email"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="" md="" sm="12" class="px-2">
            <h3 class="font-weight-medium text-body-1 mt-2">
              {{ $t('form.description["Annexe"]') }}
            </h3>
            <p class="text-caption mb-lg-6 mb-md-5 mb-3 mt-1">
              {{ $t('form.description["Saississez les informations annexes"]') }}
            </p>

            <v-row class="my-0" no-gutters v-if="role == 'admin'">
              <v-col cols="12" lg="10" md="12">
                <label
                  for="selectedRole"
                  class="font-weight-medium text-caption"
                >
                  {{ $t('form.label["Rôle"]') }}
                </label>
                <v-select
                  id="selectedRole"
                  :items="availableRoles"
                  v-model="selectedRole"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :rules="[rules.required]"
                  :disabled="isDisabled"
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="phone1" class="font-weight-medium text-caption">
                  {{ $t('form.label["Numéro de téléphone"]') }} (1)
                </label>
                <v-text-field
                  id="phone1"
                  v-model="phone1"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="phone2" class="font-weight-medium text-caption">
                  {{ $t('form.label["Numéro de téléphone"]') }} (2)
                </label>
                <v-text-field
                  id="phone2"
                  v-model="phone2"
                  variant="outlined"
                  density="compact"
                  class="mt-1"
                  :disabled="isDisabled"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-0" no-gutters>
              <v-col cols="12" lg="10" md="12">
                <label for="comment" class="font-weight-medium text-caption">
                  {{ $t('form.label["Note"]') }}
                </label>
                <v-textarea
                  id="comment"
                  v-model="comment"
                  variant="outlined"
                  density="compact"
                  hide-details=""
                  class="mt-1"
                  :readonly="isDisabled"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>

<script src="./js/userManagementForm.js"></script>